<template>
  <div class="way">
    <div class="way-title"></div>
    <div class="way-code">
      <div class="way-code-main">
        <!-- <div class="way-code-main-code" id="qrcode"></div> -->
        <qrcode-vue :value="qrcodeText" :size="113" render-as="svg">
        </qrcode-vue>
      </div>
    </div>
    <div class="way-desc">转发此链接或面对面扫码邀请</div>
    <div class="way-action">
      <div class="way-action-invite" @click="onClickInvite"></div>
      <div class="way-action-share" @click="onClickShare"></div>
    </div>
    <!-- <div class="way-red"></div> -->
    <div class="my-reward">
      <div class="my-reward-title">
        <div class="my-reward-title-name">我的奖励</div>
        <div class="my-reward-title-withdraw" @click="onClickWithdraw">
          去提现>
        </div>
      </div>
      <div class="my-reward-content">
        <div class="my-reward-content-item" @click="onClickWithdraw">
          <div class="my-reward-content-item-num" style="color: #eb5949">
            <b>{{ dataForm.sumAmount }}</b
            >元
          </div>
          <div class="my-reward-content-item-title">
            <!-- 累计获得 -->
            <img src="@/assets/img/invite/ljhd-icon.png" style="height: 17px" />
          </div>
        </div>
        <div class="my-reward-content-item" @click="inviteFeedback(1)">
          <div class="my-reward-content-item-num">
            <b>{{ dataForm.validRecommendCount }}</b
            >人
          </div>
          <div class="my-reward-content-item-title">成功邀请</div>
        </div>
        <div class="my-reward-content-item" @click="inviteFeedback(2)">
          <div class="my-reward-content-item-num">
            <b>{{ dataForm.validFeedPackCount }}</b
            >条
          </div>
          <div class="my-reward-content-item-title">有效反馈</div>
        </div>
        <div class="my-reward-content-item">
          <div class="my-reward-content-item-num">
            <b
              style="font-size: 15px; color: #eb5949"
              v-if="dataForm.forward == 1"
              >已分享</b
            >
            <b style="font-size: 15px" v-else>未分享</b>
          </div>
          <div class="my-reward-content-item-title">活动分享</div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="picShare.show"
      round
      @click-overlay="onClickCancel"
      @close="onClickCancel"
      :style="{ width: '80%', borderRadius: '10px' }"
      closeable
    >
      <div class="way-share" v-if="picShare.show">
        <div class="way-share-content">
          <div class="way-share-content-title">分享截图给好友</div>
          <div class="way-share-content-img" ref="shareImg">
            <div class="way-share-content-img-bg">
              <img
                src="@/assets/img/invite/share-openbeta-login_bg.png"
                class="bg-img"
              />
            </div>
            <div class="way-share-content-img-content">
              <div class="code">
                <div class="code_main">
                  <!-- <div class="code-main-code" id="share_qrcode"></div> -->
                  <qrcode-vue
                    :value="qrcodeText"
                    :size="100"
                    render-as="svg"
                  ></qrcode-vue>
                </div>
              </div>
              <div class="desc">快来扫描二维码，和我一起领红包奖励</div>
              <!-- <div class="rule">
                <div class="rule_title">
                  <img
                    src="@/assets/img/invite/rule_title.png"
                    class="rule_title_img"
                  />
                </div>
                <div class="rule_content">
                  亲爱的用户，为了感谢大家一直以来的关注与参与，我们推出云算房计价礼品兑换活动，您可以使用积攒的乡农网200元进行兑换，每个月每人每个档位的礼品只限兑换一种。
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="way-share-footer">
          <div
            class="way-share-footer-item"
            style="border-right: 1px solid #b1b1b1"
            @click="onClickCancel"
          >
            取消
          </div>
          <div class="way-share-footer-item" @click="onClickSave">保存本地</div>
        </div>
      </div>
    </van-popup>
    <van-overlay
      :show="overlayShow"
      :z-index="9999"
      :lock-scroll="true"
      @click="
        overlayShow = false;
        hideOptionMenu();
      "
    >
      <div class="overlay_content">
        <img class="overlay_content_img" :src="img" alt="" />
      </div>
    </van-overlay>
    <van-share-sheet
      v-model="showShare"
      title="一键邀请"
      :options="options"
      @click-overlay="onClickCancel1"
      @cancel="onClickCancel1"
      @select="onSelect"
    />
    <img
      class="imgsharethree"
      src="~@/assets/img/invite/overlay_wx.png"
      v-if="shareThree"
    />
  </div>
</template>
<script>
import html2canvas from 'html2canvas';
import QrcodeVue from 'qrcode.vue';
import Vue from 'vue';
import request from '@/utils/request';
import { getPortalRewardRecord, portalForward } from '@/api/invite';
import { Notify } from 'vant';
import wx from 'weixin-js-sdk'; //微信sdk依赖
export default Vue.extend({
  components: {
    QrcodeVue,
  },
  data() {
    return {
      shareUrl: '',
      options: [
        { name: '微信', icon: 'wechat' },
        { name: '朋友圈', icon: 'wechat-moments' },
      ],
      showShare: false,
      shareThree: false,
      baseUrl: process.env.VUE_APP_INVITE_URL,
      picShare: {
        show: false,
      },
      overlayShow: false,
      img: require('@/assets/img/invite/overlay_wx.png'),
      qrcodeText: '',
      dataForm: {},
    };
  },
  mounted() {
    this.baseUrl = process.env.VUE_APP_INVITE_URL;
    // if (sessionStorage.getItem('isOnline')) {
    //   this.getPortalRewardRecord();
    // }
    this.getPortalRewardRecord();

    this.shareUrl = process.env.VUE_APP_INVITE_URL + `/openBetaShare`;
    this.qrcodeText =
      this.shareUrl +
      `?inviterMobile=${this.$store.getters.currentLoginMobile}`;
    // this.$nextTick(() => {
    //   new qrCode(document.getElementById("qrcode"), {
    //     text:
    //       this.shareUrl +
    //       `?inviterMobile=${this.$store.getters.currentLoginMobile}&invitationMethod=0`,
    //     width: 113,
    //     height: 113,
    //     colorDark: "#000000",
    //     colorLight: "#ffffff",
    //     correctLevel: qrCode.CorrectLevel.H,
    //   });
    // });
  },
  methods: {
    getPortalForward() {
      portalForward().then(() => {
        // this.shareThree = false;
        // this.showShare = false;
      });
    },
    getSignature() {
      request({
        url:
          `${this.baseUrl}/weChat/getSignature?url=` +
          this.baseUrl +
          '/openBetaShare',
        method: 'get',
      })
        .then((res) => {
          console.log(88888, res);
          const { timestamp, noncestr, signature } = res;
          wx.config({
            debug: false,
            appId: 'wx17081ce61493aba9',
            timestamp: +timestamp,
            nonceStr: noncestr,
            signature,
            jsApiList: [
              'updateAppMessageShareData',
              'updateTimelineShareData',
              'onMenuShareAppMessage',
              'onMenuShareTimeline',
            ],
          });
          wx.ready(() => {
            const title = '云算房一智能造价云平台';
            const desc = '活动邀请';
            const link = '/openBetaShare';
            const imgUrl =
              'https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YYFUPT/log1o.png';
            wx.updateAppMessageShareData({
              title,
              desc,
              link,
              imgUrl,
              success(res) {
                console.log('成功:' + JSON.stringify(res));
              },
              fail(res) {
                console.log('失败:' + JSON.stringify(res));
              },
            });
            wx.updateTimelineShareData({
              title,
              desc,
              link,
              imgUrl,
              success(res) {
                console.log('成功:' + JSON.stringify(res));
              },
              fail(res) {
                console.log('失败:' + JSON.stringify(res));
              },
            });

            wx.error(function (res) {
              console.log('config1:' + JSON.stringify(res));
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSelect(res) {
      // if (res.name == "朋友圈") {
      //   this.getPortalForward();
      // }
      this.getPortalForward();
      this.shareThree = true;
      this.showOptionMenu();
      this.getSignature();
    },
    hideOptionMenu() {
      wx.ready(function () {
        // 隐藏
        // wx.hideMenuItems({
        //   menuList: ['menuItem:share:timeline', 'menuItem:share:appMessage'],
        // });
        wx.hideOptionMenu();
      });
    },
    showOptionMenu() {
      wx.ready(function () {
        // 显示
        wx.showOptionMenu();
      });
    },
    getPortalRewardRecord() {
      getPortalRewardRecord(
        this.$store.getters.currentLoginMobile,
        this.$store.getters.inviteToken
      ).then((res) => {
        this.dataForm = res;
      });
    },
    onClickWithdraw() {
      this.$router.push('/withdraw');
    },
    inviteFeedback(val) {
      this.$router.push('/openBetaInviteRecord?type=' + val);
    },
    onClickShare() {
      this.picShare.show = true;
      // this.$nextTick(() => {
      //   new qrCode(document.getElementById("share_qrcode"), {
      //     text:
      //       this.shareUrl +
      //       `?inviterMobile=${this.$store.getters.currentLoginMobile}&invitationMethod=0`,
      //     width: 45,
      //     height: 45,
      //     colorDark: "#000000",
      //     colorLight: "#ffffff",
      //     correctLevel: qrCode.CorrectLevel.H,
      //   });
      // });
    },
    onClickCancel() {
      this.hideOptionMenu();
      this.picShare.show = false;
    },
    onClickCancel1() {
      this.shareThree = false;
      this.hideOptionMenu();
    },
    onClickSave() {
      html2canvas(this.$refs.shareImg, {
        imageTimeout: 15000, //newline
        useCORS: true,
      }).then((canvas) => {
        let imageUrl = canvas.toDataURL('image/png'); // 将canvas转成base64图片格式
        const aLink = document.createElement('a');
        const blob = this.base64ToBlob(imageUrl);
        const evt = document.createEvent('HTMLEvents');
        evt.initEvent('click', true, true);
        aLink.download = '云算房邀请.png';
        aLink.href = URL.createObjectURL(blob);
        aLink.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
      });
      this.showOptionMenu();
      // this.picShare.show = false;
    },
    base64ToBlob(code) {
      const parts = code.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    onClickInvite() {
      this.showShare = true;
      // const ua = window.navigator.userAgent.toLowerCase();
      // // if (/MicroMessenger/i.test(ua)) {
      // //   this.overlayShow = true;
      // //   this.showOptionMenu();
      // // } else {
      // // 创建textarea标签
      // const textarea = document.createElement('textarea');
      // // 设置相关属性 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
      // textarea.readOnly = true;
      // textarea.style.position = 'absolute';
      // textarea.style.top = '-99999px';
      // // 把目标内容赋值给它的value属性
      // textarea.value =
      //   this.shareUrl +
      //   `?inviterMobile=${this.$store.getters.currentLoginMobile}`;
      // // 插入到页面
      // document.body.appendChild(textarea);
      // // 调用onselect()方法
      // textarea.select();
      // // 把目标内容复制进剪贴板, 该API会返回一个Boolean
      // const res = document.execCommand('Copy');
      // // 移除textarea标签
      // document.body.removeChild(textarea);
      // Notify({ type: 'success', message: '复制成功' });
      // // }
    },
  },
});
</script>
<style lang="less" scoped>
.way {
  background-color: none;
  padding-bottom: 15px;
  margin: 0 20px;
  border-radius: 10px;
  padding-top: 20px;
  &-title {
    background-image: url('../../../assets/img/invite/way_title.png');
    background-size: 100% 100%;
    width: 130px;
    height: 20px;
    margin: 0 auto;
  }
  &-code {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    &-main {
      background: rgba(255, 255, 255, 0.39);
      border-radius: 4px;
      border: 2px solid #f3ac34;
      font-size: 0;
    }
  }
  &-desc {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 3px;
    color: #333333;
    text-align: center;
    margin-top: 11px;
  }
  &-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    &-invite {
      width: 280px;
      height: 50px;
      background-image: url('../../../assets/img/invite/invite.png');
      background-size: 100% 100%;
    }
    &-share {
      margin-top: 10px;
      width: 280px;
      height: 50px;
      background-image: url('../../../assets/img/invite/share.png');
      background-size: 100% 100%;
    }
  }
  &-red {
    margin: 0 auto;
    margin-top: 20px;
    width: 280px;
    height: 114px;
    background-image: url('../../../assets/img/invite/red_packet.png');
    background-size: 100% 100%;
  }
  &-share {
    &-content {
      font-size: 0px;
      height: 500px;
      &-title {
        font-size: 17px;
        color: #737373;
        text-align: center;
        margin-top: 20px;
      }
      &-img {
        margin: 20px auto;
        width: 250px;
        height: 440px;
        position: relative;
        padding-bottom: 8px;
        &-bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          .bg-img {
            width: 250px;
            height: 100%;
          }
        }
        &-content {
          position: relative;
          z-index: 2;
          padding-bottom: 35px;
        }
        .code {
          display: flex;
          justify-content: center;
          padding-top: 255px;
          &_main {
            background: #fff;
            border-radius: 3px;
            border: 2px solid #f5bb59;
            padding: 2px;
            font-size: 0;
          }
        }
        .desc {
          color: #f8de6e;
          font-size: 10px;
          text-align: center;
          margin-top: 4px;
        }
        .rule {
          background-color: #fff;
          border-radius: 4px;
          margin: 6px;
          padding: 6px;
          &_title {
            font-size: 0px;
            display: flex;
            justify-content: center;
            &_img {
              width: 52px;
              height: 8px;
            }
          }
          &_content {
            margin-top: 4px;
            font-weight: 400;
            font-size: 4px;
            color: #391916;
          }
        }
      }
    }
    &-footer {
      display: flex;
      border-top: 1px solid #b1b1b1;
      &-item {
        height: 40px;
        flex: 1;
        font-size: 14px;
        color: #4576fa;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.overlay_content {
  &_img {
    width: 240px;
    float: right;
    margin-top: 14px;
    margin-right: 32px;
  }
}
.my-reward {
  background: #fff;
  width: 100%;
  border-radius: 10px;
  margin-top: 15px;
  padding-bottom: 20px;
  &-title {
    font-weight: bold;
    padding: 20px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #333333;
    &-name {
      font-size: 13px;
    }
  }
  &-content {
    font-weight: bold;
    font-size: 12px;
    color: #343434;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
      }
      &-num {
        height: 36px;
        line-height: 38px;
        b {
          font-size: 25px;
          font-weight: 500;
        }
      }
    }
  }
}
.imgsharethree {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2099;
  width: 70%;
}
</style>
